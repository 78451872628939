import { push } from 'connected-react-router';
import { makeSelectLocation } from 'containers/MainLayout/selectors';
import { call, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { Pagination, ProductSortField, QueryCategoryDetailArgs, QueryProductSearchArgs, SortDirection } from 'types/schema';
import { getDescriptionById, getProducts, getRecommendationItems } from 'utils/apollo';
import * as qs from 'utils/queryString';
import { isRecommendationTag } from 'utils/utilities';
import { createSearchQueryfromSlug, getPathSegmentFromSlug } from 'utils/getPathSegmentFromSlug';
import { Location } from 'history';
import { selectLanguage } from 'containers/LanguageProvider/selectors';
import { pathnameChecker } from 'utils/url';
import { description as descriptionActions, products as productsActions } from './actions';
import { ActionTypes } from './constants';
import { LANGUAGES } from 'utils/constants';
import { setAlcoholState } from 'containers/MainLayout/actions';
import { getCategoryIDFromSlug } from 'utils/urls/categoriesPathSegment';
import { selectProducts } from './selectors';


const MAX_PAGE_SIZE = 40;

function getProportionPageSize(page: number, size: number) {
  const requestPage = page - 1;
  return requestPage * Math.round(MAX_PAGE_SIZE / size);
}

function handleSearchValueFromLocation(location: Location) {

  /* Transform the pathname with hyphen into separate segment's slug value */
  const segment = getPathSegmentFromSlug(location);

  /* create a query string that can be parsed into an qs object. */
  const searchQuery = createSearchQueryfromSlug(location, segment);
  const searchValue: QueryProductSearchArgs | undefined = searchQuery.length > 1 ? qs.parse(searchQuery) : undefined;

  const reactRouterState = location.state;
  if (reactRouterState && reactRouterState.route === 'HomeScreenWidget') {
    return { searchValue: searchValue, segment: segment, state: reactRouterState.state };
  }
  return { searchValue: searchValue, segment: segment };
}


function* handleMarketProduct(search: QueryProductSearchArgs, localLang: string, alternateLang: string) {
  const requestParams: QueryProductSearchArgs = { ...search };
  const currentResult = yield select(selectProducts());

  if (!search.sort.length) {
    requestParams.sort = [{ field: ProductSortField.Name, order: SortDirection.Asc }];
  }

  const requestPage = requestParams.pagination.page;
  requestParams.pagination = {
    ...requestParams.pagination,
    page: requestPage
  };

  const response = yield call(getProducts, requestParams);
  if (!response.errors) {
    const earlyProducts = currentResult.loadMore && currentResult.data.filter(p => !p.loading);
    const accumulatedResponse = currentResult.loadMore ?
      {
        ...response,
        data: [...earlyProducts, ...response.data.slice(earlyProducts.length)],
      } : response;
    yield handleMarketProductSuccess(accumulatedResponse, localLang, alternateLang);
  } else {
    yield put(productsActions.failure(response.errors));
  }
}

function* handleMarketProductSuccess(response: any, localLang: string, alternateLang: string) {
  yield put(
    productsActions.success({
      result: {
        ...response,
        loadMore: false,
        localLang: localLang,
        alternateLang: alternateLang,
      },
    }),
  );
  const location = yield select(makeSelectLocation());
  const categoryID = getCategoryIDFromSlug(location.pathname.slice(1));
  const descriptionVariables = { id: categoryID as unknown as QueryCategoryDetailArgs['id'] };
  if (categoryID) {
    const description = yield call(getDescriptionById, descriptionVariables);
    if (!description.errors) {
      yield put(descriptionActions.success(description));
    } else {
      yield put(descriptionActions.failure(description.errors));
    }
  } else {
    yield put(descriptionActions.success(''));
  }

  const isAlcoholCategory = response?.data?.some((item) => item?.category?.parent?.name === 'Alcohol');
  if (isAlcoholCategory) {
    yield put(setAlcoholState(true));
  }
  window.localStorage.setItem('lang', localLang as string);
}

function* searchFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.APPLY_SEARCH);
    if (!payload.isFromCategory) {
      yield put(push({ search: qs.stringify(payload.search) }));
    }
    yield put({ type: ActionTypes.SET_SEARCH, payload: payload.search });
  }
}

function* initData(searchValue?: QueryProductSearchArgs, state?: any, size = 10) {
  yield put(productsActions.request());
  const { locale: localLang } = yield select(selectLanguage);
  const alternateLang = localLang === LANGUAGES.Vi ? LANGUAGES.Alternate || 'en' : LANGUAGES.Vi;
  const page = searchValue?.pagination?.page || 0;
  const matchLoadMorePage = page > 0 ? getProportionPageSize(page, size) : 0;
  const search: QueryProductSearchArgs = {
    sort: [],
    filter: {},
    ...searchValue,
    pagination: { page: matchLoadMorePage, size: size },
  };

  if (searchValue?.filter?.tags?.[0] && ['NEW_PRODUCTS', 'GYOMU_SUPER', 'THUONG_HIEU_KAMEREO'].includes(searchValue?.filter?.tags?.[0])) {
    search.sort = [{ field: ProductSortField.CreatedAt, order: SortDirection.Desc }];
  }
  yield put({
    type: ActionTypes.SET_SEARCH, payload: {
      ...search,
      pagination: {
        ...search.pagination,
        page: search.pagination.page + 1,
      }
    }
  });

  if (state) {
    const response = state;
    yield handleMarketProductSuccess(response, localLang, alternateLang);
  } else {
    yield call(handleMarketProduct, search, localLang, alternateLang);
  }
}

function* recommendationData(searchValue?: QueryProductSearchArgs) {
  yield put(productsActions.request());
  const pagination: Pagination = {
    size: searchValue?.pagination?.size || MAX_PAGE_SIZE,
    page: searchValue?.pagination?.page || 1,
  };

  yield put({
    type: ActionTypes.SET_SEARCH,
    payload: { pagination: pagination, filter: { tags: ['RECOMMENDED_FOR_YOU'] } },
  });

  const response = yield call(getRecommendationItems, { pagination: { ...pagination, page: pagination.page - 1 } });
  if (!response.errors) {
    yield put(productsActions.success({ result: response }));
  } else {
    yield put(productsActions.failure(response.errors));
  }
}

function* loadMoreMarketProducts() {
  const location = yield select(makeSelectLocation());
  const { searchValue } = handleSearchValueFromLocation(location);
  yield call(initData, searchValue, undefined, MAX_PAGE_SIZE);
}

export default function* marketPageSaga() {
  const location = yield select(makeSelectLocation());
  if (pathnameChecker.isProductPage(location.pathname)) {
    yield put(push('/not-found'));
  } else if (location.pathname.endsWith('/')) {
    const pathWithoutSlash = location.pathname.replace(/\/+$/g, '');
    yield put(push(`${pathWithoutSlash}`));
  }

  const { state, searchValue, segment } = handleSearchValueFromLocation(location);
  const isRecommended = isRecommendationTag(segment?.tags);
  if (!isRecommended) {
    yield fork(initData, searchValue, state);
  } else {
    yield fork(recommendationData, searchValue);
  }

  yield fork(searchFlow);
  yield takeLatest(ActionTypes.FETCH_MORE_PRODUCTS_REQUEST, loadMoreMarketProducts)
}
