import { useCallback, useEffect, useMemo, useState } from 'react';
import useLocalStorage from './useLocalStorage';

type UseReadMore = (contentId: string | number, scrollToPosition: number) => [boolean, () => void];

const useReadMore: UseReadMore = (contentId: string | number, scrollToPosition: number) => {
  const [readMoreStatus, setReadMoreStatus] = useLocalStorage<{ [x: string]: boolean } | null>('readMoreStatus', null);
  const [isShowFullDescription, setIsShowFullDescription] = useState<boolean>(false);
  const memoizedContentId = useMemo(() => contentId, [contentId]);
  useEffect(() => {
    if (memoizedContentId && readMoreStatus) {
      const readMoreStatusValue = readMoreStatus[memoizedContentId];
      setIsShowFullDescription(Boolean(readMoreStatusValue));
      if (readMoreStatusValue && scrollToPosition) {
        window.scrollTo({
          top: scrollToPosition,
        });
      }
    }
    return () => {
      if (memoizedContentId) {
        setReadMoreStatus({ [memoizedContentId]: false });
      }
    };
  }, [memoizedContentId, Boolean(readMoreStatus), scrollToPosition]);

  const handleReadMore = useCallback(() => {
    if (isShowFullDescription && scrollToPosition) {
      window.scrollTo({
        top: scrollToPosition,
      });
    }
    setIsShowFullDescription(!isShowFullDescription);
    if (memoizedContentId) {
      setReadMoreStatus({ [memoizedContentId]: !isShowFullDescription });
    }
  }, [memoizedContentId, isShowFullDescription, scrollToPosition]);

  return [isShowFullDescription, handleReadMore];

}

export default useReadMore;