
const getTheMainTextFromParagraph = (paragraph: string) => {
  const regex = /<span[^>]*>([^<]*)<\/span>\s*<\/p>$/;
  const match = paragraph.match(regex);

  if (match) {
    let extractedText = match[1].trim();
    if (extractedText.startsWith(':')) {
      extractedText = extractedText.slice(1).trim();
    }
    return extractedText;
  } 
    return '';
  
}

const takeOutTheMetaFromTheDescription = (description: string) => {
  const firstParagraph = description.match(/<p[^>]*>(?=.*Title)(.*?)<\/p>/gi)?.[0]
  const secondParagraph = description.match(/<p[^>]*>(?=.*Meta)(.*?)<\/p>/gi)?.[1];
  let titleParagraph = '';
  let metaParagraph = '';
  if (firstParagraph && firstParagraph.includes("Title")) {
    titleParagraph = firstParagraph;
    if (secondParagraph && secondParagraph.includes("Meta")) {
      metaParagraph = secondParagraph;
    }
  } else if (firstParagraph && firstParagraph.includes("Meta")) {
    metaParagraph = firstParagraph;
  }
  let refinedDescription = description;
  if (titleParagraph) {
    refinedDescription = refinedDescription.replace(titleParagraph, '');
  }
  if (metaParagraph) {
    refinedDescription = refinedDescription.replace(metaParagraph, '');
  }
  return {
    meta: getTheMainTextFromParagraph(metaParagraph),
    title: getTheMainTextFromParagraph(titleParagraph),
    description: refinedDescription,
  };
};

export default takeOutTheMetaFromTheDescription;