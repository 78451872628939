/*
 *
 * MarketPage reducer
 *
 */

import MainActionTypes from 'containers/MainLayout/constants';
import { ActionTypes } from './constants';
import { ContainerState, ContainerActions, ProductWithLoading } from './types';

const loadingResources = Array(48)
  .fill(0)
  .map((_v, i) => ({ loading: true, id: i })) as unknown as ProductWithLoading[];

export const initialState: ContainerState = {
  search: {
    sort: [],
    filter: { preservation: 'All' },
    pagination: { page: 0, size: 40 },
  },
  result: {
    data: loadingResources,
    totalPage: 0,
    totalResults: 0,
    localLang: '',
    alternateLang: '',
    loadMore: false,
  },
  description: {
    loading: false,
    id: 0,
    name: '',
    nameLocal: ''
  },
  noteItemLoading: false,
};

export default (state: ContainerState = initialState, action: ContainerActions) => {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;

    case ActionTypes.SET_SEARCH:
      return { ...state, search: { ...payload } };

    case ActionTypes.FETCH_PRODUCTS_REQUEST:
      const earlyProducts = (state.result.loadMore && state.result.data.some(p => !p.loading)) ? state.result.data : loadingResources;
      return {
        ...state,
        result: {
          ...state.result,
          data: [
            ...earlyProducts,
            ...(loadingResources).slice(earlyProducts.length)?.map((item) => ({ ...item, loading: true }))
          ]
        },
        description: { ...state.description, loading: true }
      };

    case ActionTypes.FETCH_PRODUCTS_SUCCESS:
      return { ...state, ...payload };

    case ActionTypes.FETCH_MORE_PRODUCTS_REQUEST:
      return { ...state, result: { ...state.result, loadMore: true } };

    case ActionTypes.FETCH_DESCRIPTION_REQUEST:
      return { ...state, description: { ...state.description, loading: true } };

    case ActionTypes.FETCH_DESCRIPTION_SUCCESS:
      return { ...state, description: { ...payload, loading: false } };

    case MainActionTypes.FAVORITE_SUCCESS:
      const favoriteProducts = state.result.data.map((product) => {
        return product?.id === payload.id ? { ...product, isFavorite: payload.favorite } : product;
      });
      return { ...state, result: { ...state.result, data: favoriteProducts }, loading: false };

    case MainActionTypes.SET_PRODUCT_NOTE_REQUEST:
      return { ...state, noteItemLoading: true };

    case MainActionTypes.SET_PRODUCT_NOTE_SUCCESS:
      const updatedNoteProducts = state.result.data?.map((product) => {
        return product?.id === payload.id ? { ...product, note: payload.note } : product;
      });
      return { ...state, result: { ...state.result, data: updatedNoteProducts }, noteItemLoading: false };
    case MainActionTypes.SET_PRODUCT_NOTE_FAILURE:
      return { ...state, noteItemLoading: false };
    default:
      return state;
  }
};
