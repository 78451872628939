import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const ReadMore = styled.div`
  display: flex;
  gap: 8px;
  padding-block: 1px;
  padding-inline: 8px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 22px;
  color: #008fe5;
  cursor: pointer;
  text-align: center;
  margin: 8px 0 0;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0px;
    right: 0px;
    height: 60px;
    z-index: 1;
    background: ${(overLayColor: string) => overLayColor || 'white'};
    pointer-events: none;
    & {
      ${({ isShowFullDescription }) =>
        isShowFullDescription &&
        `
        display: none;
        `}
    }
  }
`;

interface ReadMoreProps {
  isShowFullDescription: boolean;
  handleReadMore: () => void;
  messages: ReactIntl.Messages<string>;
  Icon?: React.FC<{ className?: string; rotate?: number }>;
}

const ReadMoreButton: React.FC<ReadMoreProps> = ({
  isShowFullDescription,
  handleReadMore,
  messages,
  Icon: IconComponent, // Rename the prop to avoid conflict with JSX element type
  ...rest
}) => {
  const Icon = IconComponent || (() => null); // Provide a fallback component if IconComponent is not provided
  return (
    <ReadMore onClick={handleReadMore} isShowFullDescription={isShowFullDescription} {...rest}>
      {Icon && <Icon rotate={!isShowFullDescription ? 0 : 180} />}
      {isShowFullDescription ? (
        <FormattedMessage {...messages.collapse} />
      ) : (
        <FormattedMessage {...messages.readMore} />
      )}
    </ReadMore>
  );
};

export default ReadMoreButton;
