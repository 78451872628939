module.exports.keyword = [
  'bún',
  'củ sắn',
  'nấm đùi gà',
  'bun',
  'nước mắm đệ nhị',
  'xúc xích đức',
  'dầu ăn',
  'thùng sữa chua vinamilk',
  'đậu rồng',
  'thùng bia tiger',
  'heineken bạc',
  'thùng tiger bạc',
  'nấm bạch tuyết',
  'thùng bia',
  'tiger bạc',
  'rau dền cơm',
  'nấm trắng',
  'rau diếp cá',
  'dầu ăn cái lân',
  'bia tiger bạc',
  'thùng bia heineken',
  'hạt nêm knorr',
  'nước mắm nam ngư 750ml',
  'thùng sữa chua',
  'muối hồng bạc liêu',
  'bơ tường an',
  'nam ngư đệ nhị',
  'thùng bia tiger bạc',
  'bia heineken bạc',
  'ớt sừng xanh',
  'sữa đặc ngôi sao phương nam',
  'búp măng',
  'thùng nước suối',
  'dầu cái lân',
  'dalat milk',
  'nấm bào ngư trắng',
  'thùng nước',
  'thùng tiger',
  'nước mắm nam ngư đệ nhị',
  'ớt ba tri',
  'tương ớt cholimex',
  'lá trà xanh',
  'đệ nhị',
  'hủ tiếu mềm',
  'thùng th true milk',
  'nước mắm siêu tiết kiệm',
  'sữa tươi nguyên kem th true milk',
  'bia tiger',
  'sữa th true milk không đường',
  'me nấu canh chua',
  'thùng sữa th true milk',
  'dưa hấu không hạt',
  'nấm mỡ',
  'sữa thanh trùng dalat milk',
  'đùi gà góc tư',
  'dalat milk không đường',
  'mắm đệ nhị',
  'bún sợi to',
  'trứng gà ba huân',
  'sữa dalat milk',
  'sữa đặc tài lộc',
  'màng bọc thực phẩm ringo',
  'thùng bia 333',
  'sữa tài lộc',
  'ớt sừng',
  'nước rửa chén sunlight',
  'mật ong hoa cà phê',
  'sữa barista',
  'sữa đặc ngôi sao phương nam 1284g',
  'barista milk',
  'nước mắm nam ngư',
  'chân gà đông lạnh',
  'th nguyên chất',
  'sữa tươi th true milk',
  'bánh tráng safoco',
  'bột chiên xù panko 1kg',
  'dầu ăn 5l',
  'bia ken bạc',
  'dưa leo tr',
  'táo xanh',
  'bia 333',
  'cải hồng kông',
  'bún gạo',
  'nam ngư 750ml',
  'bột thịt gà',
  'sữa đậu nành không đường',
  'tương cà cholimex',
  'bơ tường an 800g',
  'nam ngư siêu tiết kiệm',
  'sợi bún',
  'thùng heineken',
  'thùng bia 333',
  'sữa đà lạt milk không đường',
  'thùng sữa đặc ngôi sao phương nam',
  'mì trứng safoco',
  'sữa tươi vinamilk',
  'dalat milk thanh trùng',
  'đùi gà cp',
  'thùng nước mắm nam ngư 750ml',
  'nước tương maggi',
  'vfresh',
  'nước mắm đệ nhị 900ml',
  'bún bò huế',
  'sữa tươi vinamilk không đường',
  'sữa thanh trùng đà lạt milk',
  'cốt dừa wonderfarm',
  'bột chiên giòn',
  'cải sậy',
  'nước cam vfresh',
  'phô mai lát',
  'bột chiên giòn meizan',
  'nui safoco',
  'sữa tươi đà lạt milk',
  'đùi gà rút xương',
  '1 thùng sữa chua',
  'đùi gà đông lạnh',
  'dầu ăn cái lân 5l',
  'thùng sữa đặc',
  'thùng aquafina',
  'thùng redbull',
  'th true milk không đường',
  'sụn gà cp',
  'bột ngọt ajinomoto 1kg',
  'nước cốt dừa wonderfarm',
  'xoài keo',
  'tabasco',
  'thùng sữa vinamilk',
  'mắm nam ngư 750ml',
  'khoai tây bi',
  'sữa tươi thanh trùng đà lạt milk',
  'nước mắm nam ngư siêu tiết kiệm',
  'sữa th không đường',
  'sữa dalat milk không đường',
  'lá trà',
  'sữa th nguyên chất',
  'sợi bún bò huế',
  'sữa ngôi sao phương nam',
  'sữa tài lộc hộp giấy',
  'khoai môn cao',
  'miến phú hương',
  'giấy hộp',
  'khăn giấy hộp',
  'me chua',
  'đùi góc tư',
  'thùng nước aquafina',
  'me chua nấu canh',
  'siêu tiết kiệm',
  'bia saigon special',
  'chân gà cp',
  'rau muống nước',
  'nước tương tam thái tử',
  'nấm ngọc châm',
  'giá sống',
  'chân gà rút xương cp',
  'bơ thực vật tường an',
  'nam ngư',
  'sữa chua vinamilk',
  'nước tương maggi 700ml',
  'heniken bạc',
  'đà lạt milk không đường',
  'carabao',
  'vinamilk 100 sữa tươi',
  'nước cam ép vfresh',
  'sữa đặc vinamilk tài lộc',
  'giấy cuộn pulppy',
  'sữa tươi thanh trùng dalatmilk',
  'táo rockit ống 4 trái',
  'ngôi sao phương nam',
  'dầu cái lân 5l',
  'màng nhôm bọc thực phẩm',
  'cái lân',
  'bia tiger 22+2',
  'sữa tươi đà lạt',
  'panko',
  'dầu hào maggi',
  'trứng gà cp',
  'giấy hộp pulppy',
  'thùng sữa ngôi sao phương nam',
  'gà góc tư',
  'mắm siêu tiết kiệm',
  'cải thìa',
  'sữa th true milk',
  'thùng nước mắm',
  'dầu ăn',
  'bia 33',
  'xúc xích le gourmet',
  'màng nhôm',
  'thùng dầu ăn',
  'sữa tươi barista',
  'xoài keo xanh',
  'aquafina 500ml',
  'sữa tươi dalat milk không đường',
  'sữa tươi dalatmilk',
  'dầu ăn tường an 5l',
  'sữa tươi tiệt trùng vinamilk',
  'vỉ trứng cút',
  'màng bọc thực phẩm',
  'sữa đặc ngôi sao phương nam',
  'thùng sữa đặc ngôi sao phương nam',
  'dầu ăn can 30l',
  'két bia tiger bạc',
  'nước mắm nam ngư đệ nhị 900ml',
  'sữa chua thùng',
  'sữa đặc tài lộc vinamilk 1',
  '284g',
  'thùng nước mắm đệ nhị',
  'nấm đông cô tươi',
  'bí bánh xe',
  'đà lạt milk thanh trùng',
  'thùng nước cốt dừa',
  'bánh phở',
  'bột mì đa dụng số 11',
  'sữa đặc ngôi sao phương nam 1284g 1 thùng',
  'khăn giấy hộp pulppy',
  'thùng nước mắm nam ngư',
  'thùng nước suối aquafina 500ml',
  'sữa nguyên chất',
  'bia tiger bạc',
  'sữa tươi đà lạt milk không đường',
  'cholimex',
  'ớt batri',
  'nuoc mam de nhi',
  '1 thùng sữa đặc ngôi sao phương nam',
  'thùng bia ken',
  'bột mì số 13',
  'giấm gạo',
  'nui ống',
  'xà lách xoong',
  'vipep',
  'thùng nước dasani',
  'phô mai cheddar',
  'thùng strongbow',
  'sữa tươi dalat milk',
  'diếp cá',
  'dầu olita 5l',
  'cần tàu',
  'thung bia',
  'satori',
  'chân gà rút xương đông lạnh',
  'saigon special',
  'sợi bún bò',
  'bột ngọt ajinomoto',
  'sữa tươi không đường th true milk',
  'nước mắm đệ nhị',
  'sữa th true milk nguyên chất',
  'sữa đà lạt milk thanh trùng',
  'măng trúc',
  'bia sài gòn special',
  'mắm nam ngư đệ nhị',
  'soda schweppes thùng',
  'bột chanh knorr',
  'đậu hũ ta',
  'sữa đặc tài lộc vinamilk 1284g',
  'bún tươi',
  'xúc xích đức le gourmet',
  'bí ngòi',
  'nước tương tam thái tử',
  'nước suối thùng',
  'bột phô mai sajang',
  'bí đỏ bánh xe',
  'bia tiger',
  'bia heineken',
  'bột gà',
  'thùng dầu hào maggi 820g ',
  'sả băm',
  'sting lon',
  'sữa vinamilk không đường',
  'sữa dalat milk thanh trùng',
  'vfresh cam',
  'sữa đặc tài lộc - hộp giấy',
  'cốt dừa wonderfarm 1 thùng',
  'đậu nành nhật',
  'lá dứa tiếng nhật',
  'hạt tiêu đen',
  'tỏi gà đông lạnh',
  'nấm bào ngư',
  'khoai cao',
  'cánh gà cp',
  'tiger bạc thùng',
  'nấm mỡ gà',
  'thùng nước suối aquafina 500ml 24 chai',
  'thùng nước suối dasani',
  'rau cần tàu',
  'chanh dây tím',
  'thùng nước mắm nam ngư',
  'knorr',
  'đậu phộng tươi',
  'sữa tươi không đường dalat milk',
  'th true milk nguyên chất',
  'tiger bạc lon',
  'tương hột ớt lee kum kee',
  'sữa tươi dalat',
  'bột gia vị phô mai',
  'bí nụ',
  'sữa đặc tài lộc vinamilk 1 284g',
  'sữa vinamilk 1976',
  'nước cốt dừa cocoxim',
  'thùng ken bạc',
  'thùng nước lọc',
  'thùng nước tương maggi',
  'tam thái tử',
  'thùng tương ớt cholimex',
  'lá hương thảo',
  'sữa tươi th không đường',
  'hạt nêm maggi',
  'sữa barista milk',
  'rau rền cơm',
  'ức gà đông lạnh',
  'khăn giấy cuộn',
  'thùng nước ngọt',
  'vietsan',
  'dầu cái lân 25kg',
  'lá trà xanh tươi',
  'supersoy',
  'nosafood',
  'dầu cái lân 1l',
  'muoi hong bac lieu',
  'nấm nâu',
  'bia tiger bạc lon',
  '1 thùng sữa chua vinamilk',
  'bột xù panko',
  'thyme',
  'chai nước mắm đệ nhị',
  'nước lau đa năng sunlight',
  'sữa đà lạt milk không đường 1 lít',
  'me nấu canh',
  'cải mầm',
  'nấm bào ngư xám',
  'vinamilk tài lộc',
  'thùng dầu ăn simply 1l',
  'le gourmet',
  'nam ngư đệ nhị 900ml',
  'tương ớt cholimex 2.1kg thùng',
  'cam sành',
  'rau dền đỏ',
  'sữa hộp vinamilk',
  'rice vermicelli',
  'thùng sữa đặc',
  'thùng nước tương tam thái tử',
  'giò sống',
  'mayonnaise 3kg',
  'miến phú hương yến tiệc',
  'dầu ăn cái lân 1l',
  'gói s592',
  'ba chỉ bò mỹ đông lạnh',
  'bơ anchor',
  'tiger lon',
  'táo 15',
  'alba water',
  'nước mắm nam ngư 4.8 lít',
  'khăn giấy',
  'hạt nêm nấm hương',
  'nước lau kính sunlight',
  'bơ mặn',
  'dđậu rồng',
  'giấm gạo lisa',
  'xương gà đông lạnh',
  'bột mì số 8',
  'thùng dầu',
  'tương ớt cholimex 830g',
  'bột gà knorr',
  'bí đỏ bánh xe',
  'chanh vàng',
  'nam ngư',
  'bắp chuối bào',
  'nấm ngọc châm trắng',
  'nấm mèo',
  'nấm mỡ trắng',
  'chanh không hạt',
  'dua leo tr',
  'dalat milk 1 lít',
  'táo sành',
  'nước cốt dừa 400ml',
  'sữa tươi nguyên chất th true milk có đường không',
  'mascarpone',
  'khoai lang nhật',
  'sữa rửa tay lifebuoy',
  'bột ngọt ajinomoto 1kg hạt nhỏ',
  'bơ lạt',
  'chát',
  'nước tăng lực carabao',
  'xúc xích cocktail',
  'nước khoáng alba chai thuỷ tinh',
  'gừng trung quốc',
  'dầu ăn simply 5l',
  'phô mai con bò cười',
  'dầu đậu nành simply',
  'nakydaco',
  'thùng ken',
  'nước khoáng alba',
  'lẩu nấm',
  'bột chiên xù panko',
  'sữa tươi nguyên chất',
  'bột panko',
  'nước mắm vị ngư',
  'màng bọc ringo',
];
