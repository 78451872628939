import React, { FC, useMemo } from 'react';
import Link from '../Link/Link';

type SegmentObjectType = {
  name: string | undefined;
  value: string | undefined;
  codename: string | undefined;
};

interface PaginationLinkProps {
  page: number;
  isFromCategory: boolean;
  category: string;
  lang: string;
  tags?: string;
  query?: string;
}

interface SegmentLinkProps {
  page: number;
  isFromCategory: boolean;
  segmentObject: SegmentObjectType;
  lang: string;
  pageQuery?: string;
  query?: string;
}

const generateCategoryLink = (segmentObject: SegmentObjectType, lang: string, pageQuery?: string) => {
  const segmentValue = segmentObject.value ?? '';
  const segmentCodename = segmentObject.codename ?? '';
  const querySearch = pageQuery ? `${lang !== 'vi' && segmentObject.name !== 'tags' ? '&' : '?'}${pageQuery}` : '';
  return `/${segmentValue}${segmentCodename}${querySearch}`;
};

const generateSearchLink = (segmentObject: SegmentObjectType, query?: string, pageQuery?: string) => {
  const segmentParam = segmentObject.value ? `${segmentObject.name}=${segmentObject.value}` : '';
  const queryParam = query ? (!segmentParam ? `q=${query}` : `&q=${query}`) : '';
  const pageParam = pageQuery ? (!queryParam ? `${pageQuery}` : `&${pageQuery}`) : '';
  return `/market?${segmentParam}${queryParam}${pageParam}`;
};

const SegmentLink: FC<SegmentLinkProps> = ({
  segmentObject,
  isFromCategory,
  page,
  lang,
  pageQuery,
  query,
}: SegmentLinkProps) => {
  const link = isFromCategory
    ? generateCategoryLink(segmentObject, lang, pageQuery)
    : generateSearchLink(segmentObject, query, pageQuery);

  return <Link to={link}>{page}</Link>;
};

const PaginationLink = ({ page, isFromCategory, tags, category, lang, query }: PaginationLinkProps) => {
  const pageQuery = page > 1 ? `page=${page}` : '';

  /* Create a segment object */
  const segments = useMemo(
    () => ({
      tags: {
        name: 'tags',
        value: tags,
        codename: '',
      },
      category: {
        name: 'category',
        value: category,
        codename: '',
      },
    }),
    [tags, category],
  );

  const segmentObject = segments.tags.value ? segments.tags : segments.category;
  return (
    <SegmentLink
      segmentObject={segmentObject}
      pageQuery={pageQuery}
      query={query}
      isFromCategory={isFromCategory}
      lang={lang}
      page={page}
    />
  );
};

export default PaginationLink;
