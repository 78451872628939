import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the marketPage state domain
 */

const selectMarketPageDomain = (state: ApplicationRootState) => {
  return state ? state.marketPage : initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by MarketPage
 */

const selectMarketPage = () =>
  createSelector(selectMarketPageDomain, substate => {
    return substate;
  });

const selectSearch = () =>
  createSelector(selectMarketPageDomain, substate => {
    return substate.search;
  });

const selectProducts = () =>
  createSelector(selectMarketPageDomain, substate => {
    return substate.result;
  });

export default selectMarketPage;
export { selectMarketPageDomain, selectSearch, selectProducts };
